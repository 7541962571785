import bookings from '../ui/bookings'
import subscriptions from '../ui/subscriptions'
import accessControl from '../ui/access-control'
import sales from '../ui/sales'


export default {
    /**
    * @deprecated Please use ui/subscriptions/subscriptionType.label instead
    */
    subscriptionTypeStr(x) {
        return subscriptions.subscriptionType(x).label
    },

    /**
    * @deprecated Please use ui/bookings/bookingStatus.label instead
    */
    bookingStatusStr(x) {
        return bookings.bookingStatus(x).label
    },

    /**
   * @deprecated Please use ui/bookings/bookingSource.label instead
   */
    bookingSourceStr(x) {
        return bookings.bookingSource(x).label
    },

    /**
    * @deprecated Please use ui/sales/saleTypeLabel instead
    */
    saleType(sale) {
        return sales.saleType(sale).label
    },

    /**
    * @deprecated Please use ui/access-control/turnstileType.label instead
    */
    turnstileTypeStr(t) {
        return accessControl.turnstyleType(t).label
    },

    /**
    * @deprecated Please use ui/access-control/turnstileDirection.label instead
    */
    turnstileDirectionStr(t) {
        return accessControl.passingDirection(t).label
    },

    /**
    * @deprecated Please use ui/access-control/passingType.label instead
    */
    passingTypeStr(x) {
        return accessControl.passingType(x).label
    },

    /**
    * @deprecated Please use ui/access-control/passingDirectionLabel instead
    */
    passingDirectionStr(x) {
        return accessControl.passingDirection(x).label
    },

    /**
    * @deprecated Please use ui/access-control/accessControlEventTypeLabel instead
    */
    accessControlEventTypeStr(x) {
        return accessControl.accessControlEventType(x).label
    },
}

