import moment from 'moment'
import Consts from './consts'


export default {
    createdSinceOptions: [
        { text: 'Aujourd\'hui', value: moment().format('YYYY-MM-DD') },
        { text: 'Moins de 48 heures', value: moment().subtract(2, 'days').format('YYYY-MM-DD') },
        { text: 'Cette semaine', value: moment().startOf('week').format('YYYY-MM-DD') },
        { text: 'Ce mois-ci', value: moment().startOf('month').format('YYYY-MM-DD') },
        { text: 'Ce trimestre', value: moment().startOf('quarter').format('YYYY-MM-DD') },
        { text: 'Ce semestre', value: moment().year() + (moment().month() < 7 ? '-01-01' : '-07-01') },
        { text: 'Cette année', value: moment().startOf('year').format('YYYY-MM-DD') }
    ],

    salesTypes: [
        { text: 'Ventes', value: 5 },
        { text: 'Arrhes', value: 9 },
        { text: 'Avoirs', value: -1 }
    ],

    turnstilesTypes: [
        { text: 'Autre', value: Consts.ACCESS_CONTROL_OTHER },
        { text: 'Lecteur RFID (tourniquet)', value: Consts.ACCESS_CONTROL_TURNSTILE_RFID },
        { text: 'Lecteur QRcode (tourniquet)', value:Consts.ACCESS_CONTROL_TURNSTILE_QR },
        { text: 'Lecteur RFID (porte)', value: Consts.ACCESS_CONTROL_DOOR_RFID },
        { text: 'Lecteur QRcode (porte)', value: Consts.ACCESS_CONTROL_DOOR_QR },
        { text: 'Lecteur RFID (borne d\'information)', value: Consts.ACCESS_CONTROL_KIOSK_RFID },
        { text: 'Bouton poussoir', value: Consts.ACCESS_CONTROL_PUSH_BUTTON },
        { text: 'Mobile', value: Consts.ACCESS_CONTROL_MOBILE }
    ],

    turnstilesTypeAvailablesForImmediateDebit: [3,4,5],

    turnstilesDirections: [
        { text: 'Non défini', value: 0 },
        { text: 'Entrée', value: 1 },
        { text: 'Sortie', value: 2 }
    ],

    subscriptionTypes: [
        { text: 'Crédit', value: Consts.SUBSCRIPTION_TYPE_CREDIT },
        { text: 'Abonnement', value: Consts.SUBSCRIPTION_TYPE_SUBSCRIPTION },
        { text: 'Quantité', value: Consts.SUBSCRIPTION_TYPE_PASS_Q },
        { text: 'Horaire', value: Consts.SUBSCRIPTION_TYPE_PASS_H },
        { text: 'Pack', value: Consts.SUBSCRIPTION_TYPE_PACK },
        { text: 'Autre', value: Consts.SUBSCRIPTION_TYPE_OTHER },
        { text: 'QR code', value: Consts.SUBSCRIPTION_TYPE_2DTAG }
    ],

    collectionLabels: {
        sale: "Vente",
        cashbook: "Fond de caisse",
        client: "Client",
        grandtotal: "Grand total",
        param: "Paramètre",
        period: "Période",
        pricing: "Tarif",
        product: "Produit",
        voucher: "Bon",
        bookings: "Réservation",
        passings: "Passage",
        booking_slot: "Créneau"
    },
}