import Consts from '../consts'
import formsHelper from '../helpers/forms'

const SALE_TYPE_AVOIR = -1;
const SALE_TYPE_CORRECTION = -2

const saleStates = [
    { label: 'Brouillon', value: Consts.SALE_STATE_DRAFT, icon: 'mdi-file', color: '#9c9c9c' },
    { label: 'En pause', value: Consts.SALE_STATE_PAUSED, icon: 'mdi-pause', color: '#9c9c9c' },
    { label: 'En attente de paiement', value: Consts.SALE_STATE_PAYING, icon: 'mdi-credit-card-clock', color: '#9c9c9c' },
    { label: 'En attente de confirmation', value: Consts.SALE_STATE_READY_CONFIRMED, icon: 'mdi-pause', color: '#9c9c9c' },
    { label: 'Confirmée', value: Consts.SALE_STATE_CONFIRMED, icon: 'mdi-check', color: '#50c246' },
    { label: 'Annulée', value: Consts.SALE_STATE_CANCELLED, icon: 'mdi-cancel', color: '#e34242' },
    { label: 'Paiement en pause', value: Consts.SALE_STATE_PAUSED_PAYING, icon: 'mdi-credit-card-clock', color: '#9c9c9c' },
    { label: 'En attente de paiement', value: Consts.SALE_STATE_PAYMENT_PENDING, icon: 'mdi-credit-card-clock', color: '#9c9c9c' },
    { label: 'Arrhes', value: Consts.SALE_STATE_ARRHES, icon: 'mdi-credit-card-settings', color: '#9c9c9c' },
]

const discountTypes = [
    { 
        label: "Devise (toute la vente si présence d'un produit compatible)", 
        value: Consts.DISCOUNT_TYPES.PRICE, 
        icon: 'mdi-currency-eur', 
        valueDescription: 'Valeur de la remise en €',
        prefix: '€', 
        formatValue: (template) => `Remise de ${template.DiscountValue}€ sur la vente`,
        validateValueRules: [formsHelper.requiredRule, formsHelper.isNumericRule, formsHelper.isPositiveRule]
    },
    { 
        label: 'Devise (par produit compatible)', 
        value: Consts.DISCOUNT_TYPES.PRICE_BY_PRODUCT, 
        icon: 'mdi-currency-eur', 
        valueDescription: 'Valeur de la remise en €', 
        prefix: '€', 
        formatValue: (template) => `Remise de ${template.DiscountValue}€ sur chaque produit compatible`,
        validateValueRules: [formsHelper.requiredRule, formsHelper.isNumericRule, formsHelper.isPositiveRule]
    },
    { 
        label: 'Pourcentage', 
        value: Consts.DISCOUNT_TYPES.PERCENT, 
        icon: 'mdi-percent', 
        valueDescription: 'Valeur de la remise en %', 
        prefix: '%', 
        formatValue: (template) => `Remise de ${template.DiscountValue}%`,
        validateValueRules: [formsHelper.requiredRule, formsHelper.isIntegerRule, formsHelper.isPositiveRule, formsHelper.isLessOrEqualRule(100), formsHelper.isGreatherThanRule(0)]
    },
    { 
        label: 'Produits offerts', 
        value: Consts.DISCOUNT_TYPES.OFFERED_PRODUCTS, 
        icon: 'mdi-cursor-default-click-outline', 
        iconColor: 'deep-purple accent-4', 
        valueDescription: 'Nombre de produits offerts sélectionnables', 
        formatValue: (template) => {
            let productOfferedAmount = parseInt(template.DiscountValue)
            if(template.UnchoosableProductsIDs){
                productOfferedAmount += template.UnchoosableProductsIDs.length
            }

            return `${productOfferedAmount} produit${productOfferedAmount > 1 ? 's': ''} offert${productOfferedAmount > 1 ? 's': ''} `
        },
        validateValueRules: []
    },
]

const discountConditionTypes = [
    { 
        text: 'Lorsque le nombre produits achetés correspondants à la liste est égal à', 
        value: Consts.DISCOUNT_CONDITION_TYPES.EQ_PRODUCTS_AMOUNT,
        valueDescription: 'Nombre de produits',
        useProductsIDs: true,
        icon: 'mdi-equal',
        validateValueRules: [formsHelper.requiredRule, formsHelper.isIntegerRule, formsHelper.isPositiveRule]
    },
    {
        text: 'Lorsque le nombre produits achetés correspondants à la liste est supérieur ou égal à',
        value: Consts.DISCOUNT_CONDITION_TYPES.GTE_PRODUCTS_AMOUNT,
        valueDescription: 'Minimum de produits achetés',
        useProductsIDs: true,
        icon: 'mdi-greater-than-or-equal',
        validateValueRules: [formsHelper.requiredRule, formsHelper.isIntegerRule, formsHelper.isPositiveRule]
    },
    {
        text: 'Lorsque le montant total de la vente dépasse',
        value: Consts.DISCOUNT_CONDITION_TYPES.GTE_SALE_TOTAL,
        valueDescription: 'Minimum du total de la vente',
        icon: 'mdi-currency-eur',
        validateValueRules: [formsHelper.requiredRule, formsHelper.isPositiveRule]
    }
]

// Deprecared: use saleStates
const saleTypes = [
    { label: 'Vente confirmée', value: Consts.SALE_STATE_CONFIRMED },
    { label: 'Arrhes', value: Consts.SALE_STATE_ARRHES },
    { label: 'Avoir', value: SALE_TYPE_AVOIR},
    { label: 'Correction', value: SALE_TYPE_CORRECTION },
    { label: 'Vente non payée', value: Consts.SALE_STATE_PAYMENT_PENDING },
]


export default {
    saleType(sale) {
        if (sale.CreditNote && !sale.Correction) {
            return saleTypes.find(t => t.value == SALE_TYPE_AVOIR)
        } else if (sale.Correction) {
            return saleTypes.find(t => t.value == SALE_TYPE_CORRECTION)
        } else if (!sale.CreditNote && sale.State === Consts.SALE_STATE_PAYMENT_PENDING) {
            return saleTypes.find(t => t.value == Consts.SALE_STATE_PAYMENT_PENDING)
        } else if (!sale.CreditNote && sale.State === Consts.SALE_STATE_ARRHES) {
            return saleTypes.find(t => t.value == Consts.SALE_STATE_ARRHES)
        } else if (!sale.CreditNote && sale.State !== Consts.SALE_STATE_PAYMENT_PENDING && sale.State !== Consts.SALE_STATE_ARRHES) {
            return saleTypes.find(t => t.value == Consts.SALE_STATE_CONFIRMED)
        }
    },

    saleState(state){
        return saleStates.find(s => s.value == state)
    },

    discountType(type){
        return discountTypes.find(d => d.value == type)
    },

    discountConditionType(type){
        return discountConditionTypes.find(d => d.value == type)
    }
}

export {saleTypes, saleStates, discountTypes, discountConditionTypes}