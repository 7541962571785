import Consts from '../consts'

const bookingStatuses = [
    { label: 'Sur liste d\'attente', value: Consts.BOOKING_STATUS_WAITING, icon: 'mdi-clock-time-four-outline', color: 'grey' },
    { label: 'En attente', value: Consts.BOOKING_STATUS_PENDING, icon: 'mdi-clock-time-four-outline', color: '#d68800' },
    { label: 'Validée', value: Consts.BOOKING_STATUS_VALIDATED, icon: 'mdi-check', color: '#2bc700' },
    { label: 'Annulée', value: Consts.BOOKING_STATUS_CANCELLED, icon: 'mdi-cancel', color: '#c72400' }
]

const bookingRepeatStatuses = [
    { label: 'Brouillon', value: Consts.BOOKING_SLOT_REPEAT_STATUS_DRAFT, color: "grey" },
    { label: 'Activé', value: Consts.BOOKING_SLOT_REPEAT_STATUS_VALID, color: "green" },
    { label: 'Supprimé', value: Consts.BOOKING_SLOT_REPEAT_STATUS_DELETED, color: "red" },
]

const bookingSlotWebModes = [
    { value: Consts.BOOKING_SLOT_WEB_MODE_UNLIMITED, label: 'Sans limite' },
    { value: Consts.BOOKING_SLOT_WEB_MODE_ZERO, label: 'Back-office uniquement' },
    { value: Consts.BOOKING_SLOT_WEB_MODE_SPECIFIC, label: 'Limite fixée' }
]

const bookingSlotRepeatPublicationStatuses = [
    { value: Consts.BOOKING_SLOT_REPEAT_WEB_PUBLISHED_NONE, label: 'Visible en back office et non réservable', color: 'error' },
    { value: Consts.BOOKING_SLOT_REPEAT_WEB_PUBLISHED_INFO, label: 'Visible en ligne mais non réservable', color: 'warning' },
    { value: Consts.BOOKING_SLOT_REPEAT_WEB_PUBLISHED_BOOK, label: 'Visible et réservable', color: 'success' }
]

const bookingCheckCodes = [
    { value: Consts.BOOKING_CHECK_CODES.TIME_PASSED, label: 'Temps dépassé' },
    { value: Consts.BOOKING_CHECK_CODES.SLOT_OUT_OF_VISIBILITY, label: 'Créneau non visible' },
    { value: Consts.BOOKING_CHECK_CODES.NO_PRODUCT, label: 'Aucun produit compatible' },
    { value: Consts.BOOKING_CHECK_CODES.NO_VOUCHER, label: 'Unités non utilisables' },
    { value: Consts.BOOKING_CHECK_CODES.WRONG_CLIENT_GROUP, label: 'Mauvais groupe du client' },
    { value: Consts.BOOKING_CHECK_CODES.LEVEL_NOT_GOOD, label: 'Niveau incompatible' },
    { value: Consts.BOOKING_CHECK_CODES.BOOKING_CHECK_CODE_BOOKINGS_LIMIT_REACHED, label: 'Limite de réservations dépassée' },
    { value: Consts.BOOKING_CHECK_CODES.ALREADY_BOOKED, label: 'Déjà réservé' },
    { value: Consts.BOOKING_CHECK_CODES.NO_PLACE_AVAILABLE, label: 'Plus de place disponible' },
    { value: Consts.BOOKING_CHECK_CODES.NO_PLACE_AVAILABLE_WEB, label: 'Plus de place disponble pour le web' },
    { value: Consts.BOOKING_CHECK_CODES.WEB_MODE_UNAVAILABLE, label: 'Non disponible pour le web' },
    { value: Consts.BOOKING_CHECK_CODES.INCOMPATIBLE_AGE, label: 'Age incompatible' },
    { value: Consts.BOOKING_CHECK_CODES.NOT_ENOUGH_QUANTITY, label: 'Pas assez de quantité sur l\'abonnement' },
    { value: Consts.BOOKING_CHECK_CODES.NOT_ENOUGH_UNITS, label: 'Pas assez d\'unités' },
    { value: Consts.BOOKING_CHECK_CODES.SUBSCRIPTION_EXPIRED, label: 'Abonnement expiré' },
    { value: Consts.BOOKING_CHECK_CODES.BOOKING_NOT_OPEN, label: 'Réservation non ouverte' },
    { value: Consts.BOOKING_CHECK_CODES.MEDICERT_REQUIRED, label: 'Certificat médical requis' }
]

export default {
    bookingSource(x) {
        switch (x) {
            case Consts.BOOKING_SOURCE_WEB: return { icon: 'mdi-web', label: 'Réservation par le Web' }
            case Consts.BOOKING_SOURCE_CASHREGISTER: return { icon: 'mdi-cash-register', label: 'Réservation par la caisse' }
            case Consts.BOOKING_SOURCE_MOBILE: return { icon: 'mdi-cellphone-android', label: 'Réservation par l\'application mobile' }
            case Consts.BOOKING_SOURCE_KIOSK: return { icon: 'mdi-monitor-screenshot', label: 'Réservation par la borne' }
            default: return { icon: 'mdi-help', label: 'Inconnue' }
        }
    },

    bookingStatus(v) {
        return bookingStatuses.find(s => s.value == v)
    },

    bookingRepeatStatus(v) {
        return bookingRepeatStatuses.find(s => s.value == v)
    },

    bookingSlotWebMode(v) {
        return bookingSlotWebModes.find(s => s.value == v)
    },

    bookingSlotRepeatPublicationStatus(v) {
        return bookingSlotRepeatPublicationStatuses.find(s => s.value == v)
    },

    bookingCheckCode(v) {
        return bookingCheckCodes.find(c => c.value == v)
    }
}

export { bookingStatuses , bookingRepeatStatuses, bookingSlotWebModes, bookingSlotRepeatPublicationStatuses, bookingCheckCodes}
