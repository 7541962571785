import Consts from '../consts'
import moment from 'moment'

const TURNSTILE_STATE_OFFLINE = "OFFLINE";
const TURNSTILE_STATE_READY = "READY";
const TURNSTILE_STATE_ERROR = "ERROR";


const turnstilesTypes = [
    { label: 'Autre', value: 0 },
    { label: 'Lecteur RFID (tourniquet)', value: 1, icons: ['mdi-turnstile', 'mdi-nfc'] },
    { label: 'Lecteur QRcode (tourniquet)', value: 2, icons: ['mdi-turnstile', 'mdi-qrcode'] },
    { label: 'Lecteur RFID (porte)', value: 3, icons: ['mdi-door', 'mdi-nfc'] },
    { label: 'Lecteur QRcode (porte)', value: 4, icons: ['mdi-door', 'mdi-qrcode'] },
    { label: 'Lecteur RFID (borne d\'information)', value: 5, icons: ['mdi-laptop', 'mdi-nfc'] },
    { label: 'Bouton poussoir', value: 6, icons: ['mdi-turnstile', 'mdi-gesture-tap-button'] }
]

const turnstilesGroupTypes = [
    { value: 0, label: 'Autre', icon: 'mdi-help-circle' },
    { value: 1, label: 'Tourniquet', icon: 'mdi-turnstile' },
    { value: 2, label: 'Portillon PMR', icon: 'mdi-wheelchair-accessibility' },
    { value: 3, label: 'Porte', icon: 'mdi-door' },
    { value: 4, label: 'Couloir rapide', icon: 'mdi-door' }
]

const turnstilesStates = [
    { value: TURNSTILE_STATE_OFFLINE, icon: 'mdi-lightning-bolt-circle', color: '#888888' },
    { value: TURNSTILE_STATE_READY, icon: 'mdi-check-circle', color: '#41AC53' },
    { value: TURNSTILE_STATE_ERROR, icon: 'mdi-alert-circle', color: '#FF4444' }
]

const passingDirections = [
    { label: 'N/A', value: Consts.PASSING_DIRECTION_UNSET },
    { label: 'Entrée', value: Consts.PASSING_DIRECTION_IN },
    { label: 'Sortie', value: Consts.PASSING_DIRECTION_OUT },
]

const passingTypes = [
    { label: 'Lecture de badge', value: Consts.PASSING_TYPE_CARD_QUERY },
    { label: 'Passage', value: Consts.PASSING_TYPE_TURNSTILE_PASSING },
    { label: 'Sortie tardive', value: Consts.PASSING_TYPE_PUNISH_LATE_EXIT },
]

const accessControlEventTypes = [
    { label: 'Inconnu', value: Consts.ACCESS_CONTROL_EVENT_UNK },
    { label: 'Box prête', value: Consts.ACCESS_CONTROL_EVENT_BOX_READY },
    { label: 'Box en erreur', value: Consts.ACCESS_CONTROL_EVENT_BOX_ERROR },
    { label: 'Box hors-ligne', value: Consts.ACCESS_CONTROL_EVENT_BOX_OFFLINE },
    { label: 'Tourniquet prêt', value: Consts.ACCESS_CONTROL_EVENT_TS_READY },
    { label: 'Tourniquet en erreur', value: Consts.ACCESS_CONTROL_EVENT_TS_ERROR },
    { label: 'Tourniquet hors-ligne', value: Consts.ACCESS_CONTROL_EVENT_TS_OFFLINE },
    { label: 'Carte inconnue', value: Consts.ACCESS_CONTROL_EVENT_UNK_CARD },
    { label: 'Passage autorisé', value: Consts.ACCESS_CONTROL_EVENT_PASSING_ALLOW },
    { label: 'Passage refusé', value: Consts.ACCESS_CONTROL_EVENT_PASSING_DENIED },
    { label: 'Temps de passage dépassé', value: Consts.ACCESS_CONTROL_EVENT_PASSING_TIMEOUT },
    { label: 'Passage réalisé', value: Consts.ACCESS_CONTROL_EVENT_PASSING_CONFIRM },
    { label: 'Message de la box', value: Consts.ACCESS_CONTROL_EVENT_BOX_LOG },
    { label: 'Erreur mécanique de la barrière', value: Consts.ACCESS_CONTROL_EVENT_BARRIER_MECHANICAL_FAULT },
    { label: 'Signal de sécurité', value: Consts.ACCESS_CONTROL_EVENT_SAFETY_SIGNAL },
    { label: 'Dispositif de sécurité manquant', value: Consts.ACCESS_CONTROL_EVENT_SAFETY_DEVICE_MISSING },
    { label: 'Barrière trop rapide', value: Consts.ACCESS_CONTROL_EVENT_BARRIER_TOO_FAST },
    { label: 'Contact de flèche / écarteur actif', value: Consts.ACCESS_CONTROL_EVENT_BOOM_CONTACT_SWING_AWAY },
    { label: 'Vandalisme', value: Consts.ACCESS_CONTROL_EVENT_VANDALISM },
    { label: 'Détection d\'impact', value: Consts.ACCESS_CONTROL_EVENT_IMPACT_DETECTION },
    { label: 'Défaillance de l\'alimentation secteur', value: Consts.ACCESS_CONTROL_EVENT_MAINS_POWER_FAILURE },
    { label: 'Erreur de somme de contrôle de l\'EEPROM', value: Consts.ACCESS_CONTROL_EVENT_EEPROM_CHECKSUM_ERROR },
    { label: 'Réinitialisation du garde fou', value: Consts.ACCESS_CONTROL_EVENT_WATCHDOG_RESET },
    { label: 'Erreur logicielle VS', value: Consts.ACCESS_CONTROL_EVENT_SOFTWARE_ERROR_VS },
    { label: 'Erreur logicielle du système de bus', value: Consts.ACCESS_CONTROL_EVENT_SOFTWARE_ERROR_SYSTEMBUS },
    { label: 'Erreur de position d\'origine', value: Consts.ACCESS_CONTROL_EVENT_ERROR_ON_HOMING },
    { label: 'Dépassement CAN', value: Consts.ACCESS_CONTROL_EVENT_CAN_OVERRUN },
    { label: 'Défaut matériel CAN', value: Consts.ACCESS_CONTROL_EVENT_CAN_HW_FAULT },
    { label: 'Erreur de rythme', value: Consts.ACCESS_CONTROL_EVENT_HEARTBEAT_ERROR },
    { label: 'Surintensité', value: Consts.ACCESS_CONTROL_EVENT_OVER_CURRENT },
    { label: 'Surtension', value: Consts.ACCESS_CONTROL_EVENT_OVER_VOLTAGE },
    { label: 'Sous-tension', value: Consts.ACCESS_CONTROL_EVENT_UNDER_VOLTAGE },
    { label: 'Surchauffe', value: Consts.ACCESS_CONTROL_EVENT_OVER_TEMPERATURE },
    { label: 'Erreur de déclassement', value: Consts.ACCESS_CONTROL_EVENT_DERATING_ERROR },
    { label: 'Détection d\'impact 2', value: Consts.ACCESS_CONTROL_EVENT_IMPACT_DETECTION2 },
    { label: 'Erreur de communication du moteur', value: Consts.ACCESS_CONTROL_EVENT_MOTOR_COMMUNICATION_ERROR },
    { label: 'Dispositif de sécurité actif', value: Consts.ACCESS_CONTROL_EVENT_DBG_SAFETY_DEVICE_ACTIVE },
    { label: 'Échec du test de mise en marche du matériel', value: Consts.ACCESS_CONTROL_EVENT_HW_ENABLE_TEST_FAILED },
    { label: 'Échec du test du dispositif de sécurité', value: Consts.ACCESS_CONTROL_EVENT_SAFETY_DEVICE_TEST_FAILED },
    { label: 'Safety loop on detector 1 active', value: Consts.ACCESS_CONTROL_EVENT_DBG_SAFETY_LOOP_ON_DETECTOR1_ACTIVE },
    { label: 'Safety loop on detector 2 active', value: Consts.ACCESS_CONTROL_EVENT_DBG_SAFETY_LOOP_ON_DETECTOR2_ACTIVE },
    { label: 'Motor bootloader active', value: Consts.ACCESS_CONTROL_EVENT_MOTOR_BOOTLOADER_ACTIVE },
    { label: 'Motor update failure', value: Consts.ACCESS_CONTROL_EVENT_MOTOR_UPDATE_FAILURE },
    { label: 'Motor update performed', value: Consts.ACCESS_CONTROL_EVENT_MOTOR_UPDATE_PERFORMED },
    { label: 'Court-circuit loop A', value: Consts.ACCESS_CONTROL_EVENT_SHORT_CIRCUIT_LOOP_A },
    { label: 'Court-circuit loop B', value: Consts.ACCESS_CONTROL_EVENT_SHORT_CIRCUIT_LOOP_B },
    { label: 'Loop A broken', value: Consts.ACCESS_CONTROL_EVENT_WRNG_LOOP_A_BROKEN },
    { label: 'Loop B broken', value: Consts.ACCESS_CONTROL_EVENT_WRNG_LOOP_B_BROKEN },
    { label: 'Erreur de référence loop', value: Consts.ACCESS_CONTROL_EVENT_LOOP_REFERENCE_ERROR},
    { label: 'Erreur de mesure de fréquence', value: Consts.ACCESS_CONTROL_EVENT_DBG_FREQUENCY_MEASUREMENT_ERROR },
    { label: 'Erreur de surveillance de mesure A', value: Consts.ACCESS_CONTROL_EVENT_DBG_MEASUREMENT_MONITORING_A },
    { label: 'Erreur de surveillance de mesure B', value: Consts.ACCESS_CONTROL_EVENT_DBG_MEASUREMENT_MONITORING_B },
    { label: 'Erreur de multiplexage de loop', value: Consts.ACCESS_CONTROL_EVENT_DBG_LOOP_MULTIPLEX_ERROR },
    { label: 'Différence de fréquence négative', value: Consts.ACCESS_CONTROL_EVENT_DBG_NEGATIVE_FREQUENCY_DIFFERENCE },    
    { label: 'Erreur matérielle', value: Consts.ACCESS_CONTROL_EVENT_HARDWARE_ERROR },
    { label: 'Erreur loop A', value: Consts.ACCESS_CONTROL_EVENT_ERROR_LOOP_A },
    { label: 'Erreur loop B', value: Consts.ACCESS_CONTROL_EVENT_ERROR_LOOP_B },
]

export default {
    turnstilesState(state, parentState) {
        if (!parentState || parentState !== TURNSTILE_STATE_OFFLINE) {
            return turnstilesStates.find(s => s.value == state)
        } else {
            return { icon: 'mdi-help-circle', color: '#888888' }
        }
    },

    turnstyleType(v) {
        return turnstilesTypes.find(t => t.value == v)
    },

    turnstileGroupType(v) {
        return turnstilesGroupTypes.find(t => t.value == v)
    },

    passingDirection(v) {
        return passingDirections.find(d => d.value == v)
    },

    passingType(t) {
        return passingTypes.find(p => p.value == t)
    },

    accessControlEventType(x) {
        return accessControlEventTypes.find(t => t.value == x)
    },

    isPassingDateValid (date) {
        if (!date) {
            return false
        }
        const mm = moment(date)
        return mm.isValid() && mm.isAfter(moment('2005-01-01'))
    }
}

export { turnstilesTypes, passingTypes, passingDirections, turnstilesStates, accessControlEventTypes }