import consts from "../consts"

const onlineModules = [
    { text: "Planning", value: "planning", requiredPerm: "admin:planning", hasLandingPage: true },
    { text: "Contrôle d'accès", value: "ctm", requiredPerm: "admin:ctm", hasLandingPage: true },
    { text: "Clients", value: "clients", requiredPerm: "admin:clients", hasLandingPage: true },
    { text: "Statistiques", value: "stats", requiredPerm: "admin:stats", hasLandingPage: true },
    { text: "Spa", value: "spa", requiredPerm: "admin:spa", hasLandingPage: true },
    { text: "Casiers", value: "lockers", requiredPerm: "admin:lockers", hasLandingPage: true },
    { text: "Backoffice", value: "front" },
    { text: "Gestion commerciale", requiredPerm: "admin:business", value: "business", hasLandingPage: true },
    { text: "Odéon", value: "odeon" },
    { text: "Activités périodiques", value: "activities", requiredPerm:"admin:activities", hasLandingPage: true },
    { text: "Devis", value: "quotations", requiredPerm:"admin:quotations", hasLandingPage: true },
    { text: "Bornes", value: "kiosks", requiredPerm: "admin:kiosks", hasLandingPage: true }
]

const bookingTerminalPlanningTypes = [
    {text: "Créneaux groupés par activité", value: consts.BOOKING_TERMINAL_PLANNING_TYPES.GROUPED_BY_ACTIVITY},
    {text: "Créneaux mélangés", value: consts.BOOKING_TERMINAL_PLANNING_TYPES.MIXED_ACTIVITIES},
]


export { onlineModules, bookingTerminalPlanningTypes }
