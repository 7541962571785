<template>
    <div v-if="client">
        <v-form ref="form">
            <v-btn-toggle v-if="!hasQuotationAccess" v-model="client.ClientType" mandatory color="primary">
                <v-btn v-for="i in clientTypes" :key="i.value" :value="i.value">
                    <v-icon :color="client.ClientType === i.value ? 'primary': 'black'" left>
                        {{ i.icon }}
                    </v-icon>
                    {{ i.text }}
                </v-btn>
            </v-btn-toggle>

            <v-subheader v-if="client.ClientType === 2">Informations de l'entité morale</v-subheader>
            <div layout="column" v-if="client.ClientType === 2">
                <v-text-field filled dense prepend-icon="mdi-city" label="Raison sociale*" v-model="client.OrgInfo.Name" :rules="[rules.required]" clearable></v-text-field>
                <v-select filled dense attach prepend-icon="mdi-account-tie-outline" label="Type de client*" :items="orgTypes" v-model="client.OrgInfo.Type" :rules="[rules.required]" :menu-props="{'offset-y': true}"></v-select>

                <div layout="row">
                    <v-text-field filled dense prepend-icon="mdi-bank-outline" :label="'TVA INTRA' + (client.OrgInfo.Type === 1 ? '*' : '')" class="mr-1" v-model="client.OrgInfo.TVA" :rules="client.OrgInfo.Type === 1 ? [rules.required] : []" clearable></v-text-field>
                    <v-text-field filled dense :label="'SIRET' + (client.OrgInfo.Type === 1 ? '*' : '')" class="ml-1" v-model="client.OrgInfo.SIRET" :rules="client.OrgInfo.Type === 1 ? [rules.required] : []" clearable></v-text-field>
                </div>
                <div layout="row">
                    <v-text-field filled dense :label="'RCS' + (client.OrgInfo.Type === 1 ? '*' : '')" class="mr-1 ml-8" v-model="client.OrgInfo.RCS" :rules="client.OrgInfo.Type === 1 ? [rules.required] : []" clearable></v-text-field>
                    <v-text-field filled dense :label="'NAF' + (client.OrgInfo.Type === 1 ? '*' : '')" class="ml-1 " v-model="client.OrgInfo.NAF" :rules="client.OrgInfo.Type === 1 ? [rules.required] : []" clearable></v-text-field>
                </div>
            </div>

            <v-subheader v-if="client.ClientType === 1">Informations générales</v-subheader>
            <v-subheader v-if="client.ClientType === 2">Personne de contact</v-subheader>
            <div layout="row">
                <div flex="20">
                    <v-select :items="clientsGenders" v-model="client.Sex" prepend-icon="mdi-account" label="Civilité" class="mr-1" filled dense attach :menu-props="{'offset-y': true}" >
                        <template v-slot:item="{ on, item }">
                            <v-list-item v-on="on">{{ item.civilite }}</v-list-item>
                        </template>
                    </v-select>
                </div>
                <v-text-field filled dense :label="'Prénom' + ((client.ClientType === 1  || hasQuotationAccess) ? '*' : '')" v-model="client.Firstname" class="ml-1 mr-1" :rules="(client.ClientType === 1  || hasQuotationAccess) ? [rules.required] : []"></v-text-field>
                <v-text-field filled dense :label="'Nom' + ((client.ClientType === 1  || hasQuotationAccess) ? '*' : '')" v-model="client.Lastname" class="ml-1" :rules="(client.ClientType === 1  || hasQuotationAccess) ? [rules.required] : []"></v-text-field>
            </div>
            <div layout="row">
                <v-menu ref="menuBirthday" v-model="menuBirthday" :close-on-content-click="false" v-if="client.ClientType === 1"
                        transition="offset-y-transition" offset-y max-width="290px" min-width="290px" attach>
                    <template v-slot:activator="{ on }">
                        <v-text-field filled dense v-model="birthdateFormatted" @blur="birthdateDatepicker = parseDate(birthdateFormatted)"
                                      label="Date de naissance" prepend-icon="mdi-calendar" v-on="on" clearable @click:clear="birthdateDatepicker = ''"></v-text-field>
                    </template>
                    <v-date-picker v-model="birthdateDatepicker" no-title @input="menu1 = false" :first-day-of-week="1"></v-date-picker>
                </v-menu>
            </div>

            <!-- Quotations -->
            <div v-if="client.ClientType === 2 && userHasPermission('admin:quotations')">
                <v-subheader>Compte pro et devis</v-subheader>
                <v-switch v-model="client.OrgInfo.QuotationAccess" label="Autoriser ce client a réaliser des devis"/>
                <v-select
                    v-model="client.OrgInfo.QuotationSalesRepresentativeID"
                    :items="quotationsSalesRepresentatives"  item-value="ID" :item-text="(i) => { return i.Firstname + ' ' + i.Lastname }"
                    :loading="loadingQuotationsSalesRepresentatives"
                    :rules="client.OrgInfo.QuotationAccess ? [rules.required] : []"
                    prepend-icon="mdi-account-tie-hat-outline"
                    :label="'Commercial référent pour ce client' + (client.OrgInfo.QuotationAccess ? ' *' : '')"
                    filled dense attach :menu-props="{'offset-y': true, bottom: true}" />
                <v-select
                    v-model="client.OrgInfo.QuotationProductsCatalogueID"
                    :items="quotationsProductsCatalogues"  item-value="ID" item-text="Label.FR"
                    :loading="loadingQuotationsProductsCatalogues"
                    :rules="client.OrgInfo.QuotationAccess ? [rules.required] : []"
                    prepend-icon="mdi-book-open-page-variant-outline"
                    :label="'Catalogue de produits autorisés pour les devis' + (client.OrgInfo.QuotationAccess ? ' *' : '')"
                    filled dense attach :menu-props="{'offset-y': true, bottom: true}"
                >
                    <template #no-data>
                        <v-list-item>
                            Il n'existe aucun catalogue pour l'instant
                            <a class="ml-6" @click="$router.push({name: 'quotation-settings', params: { tab: 'catalogs' } })">Créer un catalogue</a>
                        </v-list-item>
                    </template>
                </v-select>
                <v-select
                    v-model="client.OrgInfo.QuotationPricingTypeID"
                    :items="quotationsPricings" item-value="ID" item-text="Label.FR"
                    :loading="loadingQuotationsPricings"
                    :rules="client.OrgInfo.QuotationAccess ? [rules.required] : []"
                    prepend-icon="mdi-currency-eur"
                    :label="'Tarification à appliquer aux produits du catalogue' + (client.OrgInfo.QuotationAccess ? ' *' : '')"
                    filled dense attach :menu-props="{'offset-y': true, bottom: true}"
                >
                    <template #no-data>
                        <v-list-item>
                            Il n'existe aucun tarif pour l'instant
                            <a class="ml-6" @click="$router.push({name: 'quotation-settings', params: { tab: 'pricings' } })">Créer un tarif</a>
                        </v-list-item>
                    </template>
                </v-select>

            </div>

            <v-subheader>Adresse</v-subheader>
            <v-textarea filled dense
                        :label="'Rue' + ((client.ClientType === 1  || hasQuotationAccess) ? '*' : '')"
                        v-model="clientAddress"
                        prepend-icon="mdi-home-city-outline" rows="3"
                        :rules="hasQuotationAccess ? [rules.required] : []" />
            <div layout="row">
                <v-text-field filled dense :label="'Code Postal' + (hasQuotationAccess ? '*' : '')" v-model="client.Address.PostalCode" class="mr-1" prepend-icon="mdi-map-marker" :rules="hasQuotationAccess ? [rules.required] : []"></v-text-field>
                <v-text-field filled dense :label="'Ville' + (hasQuotationAccess ? '*' : '')" v-model="client.Address.City" class="ml-1" :rules="hasQuotationAccess ? [rules.required] : []"></v-text-field>
            </div>

            <v-subheader>Contact</v-subheader>
            <div layout="row">
                <v-text-field filled dense label="Téléphone fixe" class="mr-1" v-model="client.Contact.Phone" prepend-icon="mdi-phone"></v-text-field>
                <v-text-field filled dense label="Téléphone mobile" class="ml-1" v-model="client.Contact.Mobile" ></v-text-field>
            </div>
            <v-text-field filled dense :label="'E-mail' + (hasQuotationAccess ? '*' : '')" v-model="client.Contact.Email" prepend-icon="mdi-email" :rules="hasQuotationAccess ? [rules.required] : []"
                          @focusout="checkMail" @focusin="mailValidation.Status = null">
                <div slot="append" v-if="mailValidationLoading" class="d-flex align-center">
                    <v-progress-circular indeterminate size="25" color="primary"></v-progress-circular><span class="ml-2 primary--text">Vérification...</span>
                </div>
                <div slot="append" v-else-if="mailValidation.Status !== null" layout="row" layout-align="center center">
                    <v-icon v-if="mailValidation.Status === C.MAIL_STATUS_OK" color="success">mdi-check</v-icon>
                    <v-icon v-else-if="mailValidation.Status === C.MAIL_STATUS_UNDETERMINED" color="orange">mdi-alert</v-icon>
                    <v-icon v-else color="error">mdi-close</v-icon>
                    <span class="ml-2" :class="{
                        'success--text': mailValidation.Status === C.MAIL_STATUS_OK,
                        'orange--text': mailValidation.Status === C.MAIL_STATUS_UNDETERMINED,
                        'error--text': mailValidation.Status !==C.MAIL_STATUS_OK && mailValidation.Status !== C.MAIL_STATUS_UNDETERMINED,
                    }">{{mailValidation.Msg}}</span>
                </div>
            </v-text-field>

            <v-textarea filled dense label="Commentaires" v-model="client.Comment" prepend-icon="mdi-comment-text" rows="3"></v-textarea>

            <v-subheader v-if="!hasQuotationAccess">Autres</v-subheader>
            <div v-if="!hasQuotationAccess" layout="row">
                <div flex="48">
                    <v-select :value="currentClientPricing.id" label="Tarification" :items="pricingOptions"
                              filled dense attach prepend-icon="mdi-currency-eur" :menu-props="{'offset-y': true, top: true}" :persistent-hint="!!currentClientPricing.expiration" :hint="currentClientPricing.expiration ? `Valable jusqu'au ${formatDate(currentClientPricing.expiration, 'DD/MM/YYYY')}` : null"
                              @input="_updateClientPricing" />
                </div>
                <div flex="48">
                    <v-autocomplete v-if="!isParent" v-model="parentSearch.select"
                                    :items="parentSearch.items" :item-text="formatParent"
                                    :loading="parentSearch.loading"
                                    ref="parentSearchField" autocomplete="off"
                                    :search-input.sync="parentSearch.search"
                                    label="Compte parent" no-data-text="Aucun client"
                                    item-value="ID" prepend-icon="mdi-account-child"
                                    class="ml-3"
                                    filled dense clearable
                                    no-filter return-object
                                    attach
                                    :menu-props="{top: true}"
                    >
                        <template v-slot:selection="data">
                            <v-list-item-content>
                                <v-list-item-title>{{ data.item.Firstname + ' ' + data.item.Lastname }}</v-list-item-title>
                                <v-list-item-subtitle v-if="data.item.BirthDate">{{ data.item.BirthDate ? formatBirthDate(data.item.BirthDate) : ''}}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="data.item.Contact.Email">{{ data.item.Contact.Email ? data.item.Contact.Email : ''}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title>{{ data.item.Firstname + ' ' + data.item.Lastname }}</v-list-item-title>
                                <v-list-item-subtitle v-if="data.item.BirthDate">{{ data.item.BirthDate ? formatBirthDate(data.item.BirthDate) : ''}}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="data.item.Contact.Email">{{ data.item.Contact.Email ? data.item.Contact.Email : ''}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </div>
            </div>
            <div layout="row" v-if="displayLockersFields && !hasQuotationAccess">
                <v-icon class="mb-5" left>mdi-lock-outline</v-icon>
                <v-text-field
                    v-model="client.LockerCode"
                    name="userlockercode"
                    autocomplete="new-password"
                    label="Code de l'usager pour ouvrir et fermer les casiers"
                    hint="Vous pouvez modifier le code de l'usager ici si besoin"
                    :type="lockerCodeReveal ? 'text' : 'password'"
                    :rules="[rules.userCodeFormat, rules.userCodeMin, rules.userCodeMax]"
                    filled dense
                ></v-text-field>
                <v-btn class="mt-3 ml-3" icon @click="lockerCodeReveal = !lockerCodeReveal">
                    <v-icon> {{ lockerCodeReveal ? 'mdi-eye' : 'mdi-eye-off' }} </v-icon>
                </v-btn>
            </div>

        </v-form>
    </div>
</template>

<script>
// global
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
// api
import ClientsApi from '@/api/clients.js'
import QuotationsApi from '@/api/quotations.js'
// mixins
import AuthMixin from '@/mixins/auth.js'
import ClientsMixin from 'neptune/mixins/client.js'
// others
import C from 'neptune/consts'
// vue components

export default {
    mixins: [AuthMixin, ClientsMixin],

    props: {
        client: {
            type: Object,
            required: true
        },
        hasQuotationAccess: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data () {
        return {
            orgTypes: [
                { text: 'Société', value: 1 },
                { text: 'Association', value: 2 },
                { text: 'École', value: 3 },
                { text: 'Particulier', value: 8 },
                { text: 'Privé', value: 9 },
                { text: 'Établissement public', value: 10 }
            ],
            rules: {
                required: value => !!value || 'Veuillez remplir ce champ',
                userCodeFormat: (value) => (!isNaN(parseInt(value)) && value >= 0) || !value || 'Le code ne doit comporter que des chiffres (nombre positif)',
                userCodeMin: (value) => (value && value.length >= C.LOCKERS.USER_CODE.MIN_LENGTH) || !value || 'Le code doit comporter au minimum ' + C.LOCKERS.USER_CODE.MIN_LENGTH + ' chiffres',
                userCodeMax: (value) => (value && value.length <= C.LOCKERS.USER_CODE.MAX_LENGTH) || !value || 'Le code doit comporter au maximum ' + C.LOCKERS.USER_CODE.MAX_LENGTH + ' chiffres'
            },
            lockerCodeReveal: false,

            birthdateFormatted: '',
            birthdateDatepicker: '',
            menuBirthday: false,

            mailValidation: {
                Status: null,
                Msg: ''
            },
            mailValidationLoading: false,

            parentSearch: {
                loading: false,
                items: [],
                search: '',
                select: null
            },

            isParent: false,
            clientTypes: [
                { text: 'Personne physique', value: 1, icon: 'mdi-account' },
                { text: 'Personne morale', value: 2, icon: 'mdi-city' }
            ],

            quotationsSalesRepresentatives: [],
            quotationsProductsCatalogues: [],
            quotationsPricings: [],
            loadingQuotationsSalesRepresentatives: false,
            loadingQuotationsProductsCatalogues: false,
            loadingQuotationsPricings: false,
            C
        }
    },

    computed: {
        ...mapGetters(['currentPeriod', 'pricing']),
        pricingOptions () {
            const out = []
            this.pricing.forEach(p => {
                if (p && p.PeriodID && p.PeriodID.findIndex(id => id === this.currentPeriod.ID) !== -1) {
                    out.push({ text: p.Label.FR, value: p.ID })
                }
            })
            return out
        },
        displayLockersFields () {
            const modules = this.$store.getters.modules
            return modules.lockers
        },
        currentClientPricing () {
            if (this.client.Pricings && this.client.Pricings.length > 0) {
                const accountPricing = this.client.Pricings.find(p => this.isPast(p.From) && (!p.To || this.isFuture(p.To)))
                if (accountPricing) {
                    return {
                        id: accountPricing.PricingID,
                        expiration: accountPricing.To
                    }
                }
            }

            return { id: this.currentClientPricingID(this.client, this.currentPeriod.ID) }
        },

        clientAddress: {
            get () {
                return (this.client.Address.Streetnum ? this.client.Address.Streetnum + ' ' : '') + this.client.Address.Address
            },
            set (val) {
                if (val) {
                    const re = /(\d+) (.*)/
                    const matches = val.trim().match(re)
                    if (matches && matches.length === 3) {
                        this.client.Address.Streetnum = matches[1]
                        this.client.Address.Address = matches[2].trim()
                    } else {
                        this.client.Address.Streetnum = ''
                        this.client.Address.Address = val.trim()
                    }
                } else {
                    this.client.Address.Streetnum = ''
                    this.client.Address.Address = ''
                }
            }
        }
    },

    watch: {
        'birthdateDatepicker' (v) {
            if (v) {
                this.birthdateFormatted = this.formatDate(v)
                this.client.BirthDate = moment(v).format()
            } else {
                this.client.BirthDate = null
            }
        },

        'client.BirthDate' (v) {
            if (v) {
                this.birthdateFormatted = this.formatBirthDate(this.client.BirthDate)
                this.birthdateDatepicker = this.parseDate(this.birthdateFormatted)
            } else {
                this.birthdateFormatted = null
                this.birthdateDatepicker = null
            }
        },

        client () {
            this.initParentSearch()
            this.checkIsParent()
            this.mailValidation = { Status: null, Msg: '' }
            this.checkMail()
        },

        'parentSearch.search' (val) {
            ((this.parentSearch.select && val !== this.formatParent(this.parentSearch.select)) || !this.parentSearch.select) && this.performParentSearch(val)
        },

        'parentSearch.select' (val) {
            this.client.Parent = (val !== undefined && val !== null) ? val.ID : ''
        }
    },

    mounted () {
        this.birthdateFormatted = this.formatBirthDate(this.client.BirthDate)
        this.birthdateDatepicker = this.parseDate(this.birthdateFormatted)

        this.initParentSearch()
        this.checkIsParent()
        if (this.hasQuotationAccess) {
            this.$emit('update:client', Object.assign(this.client, { ClientType: 2 }))
            this.$emit('update:client', Object.assign(this.client.OrgInfo, { QuotationAccess: true }))
        }
        if (this.userHasPermission('admin:quotations')) {
            this.loadingQuotationsSalesRepresentatives = true
            this.loadingQuotationsProductsCatalogues = true
            this.loadingQuotationsPricings = true
            QuotationsApi.getQuotationSalesRepresentatives()
                .then((res) => { this.quotationsSalesRepresentatives = res.data || [] })
                .catch((e) => { this.$snotify.error('Impossible de charger les commerciaux.'); console.error(e) })
                .finally(() => { this.loadingQuotationsSalesRepresentatives = false })
            QuotationsApi.getQuotationProductsCatalogues({})
                .then((res) => { this.quotationsProductsCatalogues = res.data || [] })
                .catch((e) => { this.$snotify.error('Impossible de charger les catalogues des produits des devis.'); console.error(e) })
                .finally(() => { this.loadingQuotationsProductsCatalogues = false })
            QuotationsApi.getQuotationPricings({})
                .then((res) => { this.quotationsPricings = res.data || [] })
                .catch((e) => { this.$snotify.error('Impossible de charger les tarifications pour les devis.'); console.error(e) })
                .finally(() => { this.loadingQuotationsPricings = false })
        }
    },

    methods: {
        isValid () {
            return this.$refs.form.validate()
        },

        resetForm () {
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
            this.client.ClientType = 1
        },

        checkMail () {
            if (this.client.Contact.Email !== '') {
                this.mailValidationLoading = true
                ClientsApi.checkMail(this.client.Contact.Email).then(res => {
                    if (res.data !== null) {
                        this.mailValidation = res.data
                        this.mailValidationLoading = false
                    }
                })
            }
        },

        checkIsParent () {
            this.isParent = false
            ClientsApi.getClients({
                Parent: this.client.ID,
                Limit: 1
            }).then(res => {
                if (res.data.Items !== null) {
                    this.isParent = res.data && res.data.Clients && res.data.Clients.length > 0
                }
            })
        },

        initParentSearch () {
            this.parentSearch = {
                loading: false,
                items: [],
                search: '',
                select: null
            }
            if (this.client.Parent && this.client.Parent !== '') {
                this.parentSearch.loading = true
                ClientsApi.getClient(this.client.Parent).then(res => {
                    if (res.data !== null) {
                        this.parentSearch.select = res.data
                        this.parentSearch.items = [res.data]
                    }
                    this.parentSearch.loading = false
                })
            }
        },

        performParentSearch: _.debounce(function (search) {
            this.parentSearch.loading = true
            ClientsApi.getClients({
                Search: search,
                Parent: 'empty'
            }).then(res => {
                if (res.data.Items === null) {
                    res.data.Items = []
                }
                this.parentSearch.items = res.data.Items
                this.parentSearch.loading = false
            })
        }, 100),

        formatParent (p) {
            let out = p.Firstname + ' ' + p.Lastname
            if (this.formatBirthDate(p.BirthDate)) {
                out += ' - ' + this.formatBirthDate(p.BirthDate)
            }
            if (p.Contact.Email !== '') {
                out += ' - ' + p.Contact.Email
            }
            return out
        },

        _updateClientPricing (newPricingID) {
            this.updateClientPricing(this.client, this.pricing.find(p => p.ID === newPricingID))
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-subheader {
        height: 32px;
        align-items: start;
    }
</style>
