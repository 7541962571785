<template>
    <v-card>
        <v-card-title>
            <div flex>{{ !infoOnly ? 'Scanner un e-billet/bon cadeau' : 'Informations du e-billet/bon cadeau' }}</div>
            <v-btn icon @click="close()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-window v-model="step" touchless>
                <v-window-item key="ticket">
                    <div v-if='!infoOnly' layout="row" layout-align="center center" class="mb-3">
                        <v-text-field v-model="voucherID" autofocus filled hide-details
                                      label="Appuyez ici pour scanner un e-billet" clearable @click:clear="resetEticket"
                                      ref="voucherField" prepend-icon="mdi-ticket-percent" @keypress.enter="validateEticket"
                                      :disabled="validating" :loading="validating"></v-text-field>
                        <v-btn text color="primary" class="ml-2" @click="searchEticket" :loading="giftVouchersLoading">
                            <v-icon>mdi-magnify</v-icon>
                            Recherche manuelle
                        </v-btn>
                    </div>

                    <v-alert v-if="failedToCheckVoucher" type="error" border="left">
                        Une erreur est survenue, veuillez réessayer
                    </v-alert>
                    <div v-else-if="voucher">
                        <v-alert type="success" border="left" v-if="voucher.Valid">
                            <div><strong>Cet e-billet est valide</strong></div>
                            <div>Acheté le
                                {{ formatDateTimeHuman(voucher.Voucher.BeginValidityDate) }}
                                <span v-if="voucher.ClientSale.Firstname || voucher.ClientSale.Lastname">par {{
                                    voucher.ClientSale.Firstname }} {{ voucher.ClientSale.Lastname }}</span>
                                <span v-if="voucher.Voucher.Firstname || voucher.Voucher.Lastname">pour {{
                                    voucher.Voucher.Firstname }} {{ voucher.Voucher.Lastname }}
                                </span>
                                <span v-else-if="voucher.ClientGift && (voucher.ClientGift.Firstname || voucher.ClientGift.Lastname)">pour {{
                                    voucher.ClientGift.Firstname }} {{ voucher.ClientGift.Lastname }}
                                </span>
                            </div>
                            <div>Valeur d'achat de {{ formatMoney(voucher.Voucher.Value) }} &euro;</div>
                            <div v-if="voucher.SheetName">Émis via la planche "{{ voucher.SheetName }}"</div>
                            <div v-if="voucher.Comment">Commentaire :  {{ voucher.Comment }}</div>
                        </v-alert>
                        <v-alert type="error" border="left" v-else>
                            <span v-if="!voucher.Exist">
                                Ce bon n'existe pas
                            </span>
                            <div v-else-if="voucher.InvalidReason === 'already-used'">
                                <div>
                                    <strong>Cet e-billet a déjà été consommé {{ voucher.Voucher.UsageDate ? ` le
                                    ${formatDateTimeHuman(voucher.Voucher.UsageDate)}` : '' }}</strong>
                                </div>
                                <div>
                                    Acheté par {{ voucher.ClientSale.Firstname }}
                                    {{ voucher.ClientSale.Lastname }}
                                    <span v-if="voucher.Voucher.Firstname || voucher.Voucher.Lastname">pour
                                        {{
                                            `${voucher.Voucher.Firstname} ${voucher.Voucher.Lastname}`
                                        }}
                                    </span>
                                    <span v-if="voucher.ClientGift && voucher.ClientGift.ID">
                                        et crédité sur le compte de
                                        {{
                                            `${voucher.ClientGift.Firstname} ${voucher.ClientGift.Lastname}`
                                        }}</span>
                                    <span v-if="voucher.Voucher.UsageSeller"> par {{ voucher.Voucher.UsageSeller }}</span>.
                                </div>
                                <div>Valeur d'achat de {{ formatMoney(voucher.Voucher.Value) }} &euro;</div>
                                <div v-if="voucher.SheetName">Émis via la planche "{{ voucher.SheetName }}"</div>
                                <div v-if="voucher.Comment">Commentaire :  {{ voucher.Comment }}</div>
                            </div>
                            <span v-else-if="voucher.InvalidReason === 'sale-not-exist'">
                                La vente d'origine de ce bon est inconnue
                            </span>
                            <span v-else-if="voucher.InvalidReason === 'outdated'">
                                Ce bon est périmé depuis le {{ formatDateTimeHuman(voucher.Voucher.EndValidityDate) }}.
                            </span>
                            <span v-else-if="voucher.InvalidReason === 'cancelled'">
                                Ce bon a été annulé pour la raison suivante :<br>
                                {{ voucher.Voucher.CancellationReason }}
                            </span>
                            <span v-else>
                                Ce bon ne semble pas valide.
                            </span>
                        </v-alert>
                        <div v-if="voucher.InvalidReason === 'already-used'">
                            <div class="text-h6">
                                Produit{{
                                    voucher.Voucher.DeliveredItems.length > 1 ? "s" : ""
                                }}
                                consommé{{
                                    voucher.Voucher.DeliveredItems.length > 1 ? "s" : ""
                                }} :
                            </div>

                            <v-card outlined flat class="d-flex my-1" v-for="(p, i) in voucherDeliveredProducts" :key="i">
                                <v-avatar tile color="grey" size="128">
                                    <div class="d-flex flex-column">
                                        <v-icon :color="p.action.color || 'white'">
                                            {{ p.action.icon }}
                                        </v-icon>
                                        <span class="white--text mx-3 mt-2 text-subtitle-2">{{p.product.LabelShort.FR}}</span>
                                    </div>
                                </v-avatar>
                                <div class="full-width">
                                    <v-card-title layout="row" layout-align="space-between start">
                                        <div>
                                            <div>{{ p.product.Label.FR }} </div>

                                            <div class="text-subtitle-1"
                                                 :class="p.action.color ? `${p.action.color}--text font-weight-bold` : ''">
                                                {{ p.action.text }}
                                            </div>

                                            <div v-if="p.product.Stock === 0" class="text-subtitle-2 red--text">
                                                Ce produit n'est plus en stock
                                            </div>
                                        </div>

                                        <v-spacer />

                                        <div>
                                            <v-alert v-if="p.UsedAmount === 0" class="ma-0 pt-1 pb-1" border="left"
                                                     color="blue" text>Quantité: {{ p.Amount }}</v-alert>
                                            <div v-else layout="column">
                                                <v-alert class="ma-0 pt-1 pb-1" border="left" color="green" text>Restant: {{
                                                    p.Amount - p.UsedAmount }}</v-alert>
                                                <v-alert class="ma-0 mt-2 pt-1 pb-1" border="left" color="red" text>Utilisé:
                                                    {{ p.UsedAmount }}</v-alert>
                                            </div>
                                        </div>

                                        <div>
                                        </div>
                                    </v-card-title>
                                </div>
                            </v-card>

                        </div>
                    </div>

                    <div v-if="voucher && voucher.Valid && voucher.Voucher && voucher.Voucher.ID">
                        <v-alert type="info" border="left" dense>
                            Bon émis le
                            {{ formatDateHuman(voucher.Voucher.BeginValidityDate) }}
                            <strong>{{
                                voucher.Voucher.EndValidityDate
                                    ? " et valable jusqu'au " +
                                        formatDateHuman(voucher.Voucher.EndValidityDate)
                                    : ""
                            }}</strong>
                        </v-alert>
                        <div v-if="voucher && voucher.Voucher.DeliveredItems &&
                            voucher.Voucher.DeliveredItems.length > 0
                        ">
                            <div class="text-h6">
                                Produit{{
                                    voucher.Voucher.DeliveredItems.length > 1 ? "s" : ""
                                }}
                                crédité{{
                                    voucher.Voucher.DeliveredItems.length > 1 ? "s" : ""
                                }}
                                par ce bon :
                            </div>

                            <v-card outlined flat class="d-flex my-1" v-for="(p, i) in voucherDeliveredProducts" :key="i">
                                <v-avatar tile color="grey" size="128">
                                    <div class="d-flex flex-column">
                                        <v-icon :color="p.action.color || 'white'">
                                            {{ p.action.icon }}
                                        </v-icon>
                                        <span class="white--text mx-3 mt-2 text-subtitle-2">{{
                                            p.product.LabelShort.FR
                                        }}</span>
                                    </div>
                                </v-avatar>
                                <div class="full-width">
                                    <v-card-title class="d-flex align-center">
                                        <v-col cols="10" class="pa-0">
                                            <div>
                                                <div>{{ p.product.Label.FR }}</div>
                                                <div
                                                    class="text-subtitle-1"
                                                    :class="p.action.color ? `${p.action.color}--text font-weight-bold` : ''"
                                                >
                                                    {{ p.action.text }}
                                                </div>
                                                <div v-if="p.product.Stock === 0" class="text-subtitle-2 red--text">
                                                    Ce produit n'est plus en stock
                                                </div>
                                            </div>
                                        </v-col>

                                        <v-col cols="2" align-self="start" class="pa-0">
                                            <div class="d-flex flex-column">
                                                <v-alert
                                                    v-if="p.UsedAmount === 0"
                                                    class="ma-0 pt-1 pb-1"
                                                    border="left"
                                                    color="blue"
                                                    text
                                                >
                                                    Quantité: {{ p.Amount }}
                                                </v-alert>
                                                <v-alert v-else class="ma-0 pt-1 pb-1" border="left" color="green" text>
                                                    Restant: {{ p.Amount - p.UsedAmount }}
                                                </v-alert>
                                                <v-alert
                                                    v-if="p.UsedAmount !== 0"
                                                    class="ma-0 mt-2 pt-1 pb-1"
                                                    border="left"
                                                    color="red"
                                                    text
                                                >
                                                    Utilisé: {{ p.UsedAmount }}
                                                </v-alert>
                                            </div>
                                        </v-col>
                                    </v-card-title>
                                </div>
                            </v-card>
                        </div>

                        <div v-if="voucher &&
                            voucher.Voucher.ProductsIDs &&
                            voucher.Voucher.ProductsIDs.length > 0
                        ">
                            <div class="text-h6">Produits à choisir pour ce bon :</div>
                            <v-item-group v-model="selectedProductID" mandatory>
                                <v-item v-for="productId in voucher.Voucher.ProductsIDs" :key="productId"
                                        v-slot="{ active, toggle }" :value="productId">
                                    <v-card outlined flat @click="toggle" layout="row">
                                        <v-avatar tile color="grey" size="128">
                                            <span class="white--text ma-3">{{
                                                mappedProducts[productId].Label.FR
                                            }}</span>
                                        </v-avatar>
                                        <v-card-text>
                                            <div class="text-h5">
                                                {{ mappedProducts[productId].Label.FR }}
                                            </div>
                                            <div v-if="mappedProducts[productId].Stock === 0" class="red--text">
                                                Ce produit n'est plus en stock
                                            </div>

                                            <div v-if="active" class="d-flex justify-end">
                                                <v-icon large color="success">mdi-check</v-icon>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-item>
                            </v-item-group>
                        </div>
                    </div>
                    <div class="d-flex justify-space-between ma-2 mt-4" v-if="voucher &&
                        voucher.Voucher &&
                        voucher.Valid &&
                        (((!voucher.Voucher.ProductsIDs ||
                            voucher.Voucher.ProductsIDs.length === 0) &&
                            voucher.Voucher.DeliveredItems &&
                            voucher.Voucher.DeliveredItems.length > 0) ||
                            (voucher.Voucher.ProductsIDs &&
                                voucher.Voucher.ProductsIDs.length > 0 &&
                                selectedProductID))
                    ">
                        <v-switch v-if="!productsAlreadyCreditCagnotte(voucher.Voucher)" v-model="convertToCredit"
                                  :label="'Convertir le bon en cagnotte pour une valeur de ' + formatMoney(voucher.Voucher.Value) + '€'"
                                  dense hide-details :disabled="voucher.Used || isPartiallyUsed(voucher.Voucher)" class="mt-0" />
                        <v-spacer />
                        <v-btn text color="green" @click="onProductSelected">{{ hasClient ? "Valider le bon" : "Suivant" }}
                            <v-icon right>mdi-arrow-right</v-icon>
                        </v-btn>
                    </div>
                </v-window-item>

                <v-window-item key="account">
                    <div class="text-h6 mb-3">Compte crédité</div>

                    <div layout="row" layout-align="center center" v-if="!newClientMode">
                        <v-autocomplete filled prepend-icon="mdi-account"
                                        label="Sélectionnez le client auquel associer ce produit" ref="clientSearchField"
                                        :loading="clientSearch.loading" :items="clientSearch.items" :item-text="clientFullname"
                                        autocomplete="off" :disabled="voucher && !!voucher.ClientGift && !!voucher.ClientGift.ID"
                                        :search-input.sync="clientSearch.search" item-value="ID" v-model="clientSearch.select"
                                        hide-details no-data-text="Aucun client" no-filter return-object></v-autocomplete>

                        <div class="text-caption mx-5">- ou -</div>

                        <v-btn text color="primary" @click="newClientMode = true"
                               :disabled="voucher && !!voucher.ClientGift && !!voucher.ClientGift.ID">
                            <v-icon left>mdi-account-plus</v-icon>
                            Nouveau client
                        </v-btn>
                    </div>

                    <client-form v-if="newClientMode" ref="clientForm" :client="newClient"></client-form>

                    <!-- choose card type per product if selected client has multiple compatible card type -->
                    <div v-if="(hasClient || allCanGoOnQRCode) && !convertToCredit">
                        <v-row v-for="(card, cardIndex) of deliveredCards" :key="`carttype-${card.ProductID}-${cardIndex}`"
                               class="ma-2" align="center">
                            <v-col cols="1">
                                <v-icon>mdi-credit-card-plus</v-icon>
                            </v-col>
                            <v-col cols="4">
                                <span class="text-subtitle-2">{{ mappedProducts[card.ProductID].Label.FR }}</span>
                            </v-col>
                            <v-col cols="1">
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-col>
                            <v-col cols="6">
                                <v-btn v-if="associatedCards.find(c => c.deliveredCardIndex == cardIndex)" block
                                       @click="associateCard(mappedProducts[card.ProductID].cardType, cardIndex)">
                                    Associé
                                    <v-icon right color="success">mdi-check</v-icon>
                                </v-btn>
                                <v-btn block v-else-if="wvEnabled()"
                                       @click="associateCard(mappedProducts[card.ProductID].cardType, cardIndex)">
                                    <v-icon left>mdi-credit-card-wireless</v-icon>
                                    Associer
                                </v-btn>
                                <v-alert border="bottom" colored-border elevation="2" type="warning" v-else>Impossible
                                    d'associer ce support hors caisse
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row v-for="sub of deliveredSubscriptions" :key="'carttypechooser-' + sub.ProductID" class="ma-2"
                               align="center">
                            <v-col cols="1">
                                <v-icon>mdi-credit-card-scan</v-icon>
                            </v-col>
                            <v-col cols="4">
                                <span class="text-subtitle-2">{{ sub.Amount }}x
                                    {{ mappedProducts[sub.ProductID].Label.FR }}</span>
                            </v-col>
                            <v-col cols="1">
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-col>
                            <v-col cols="6">
                                <v-select filled dense hide-details :value="productCardAssignation[sub.ProductID]"
                                          mandatory :items="productCompatibleCardTypes(sub.ProductID)" item-text="label"
                                          item-value="cardType" @change="(cardType) => setProductCardtype(sub.ProductID, cardType)">
                                    <template v-slot:item="{ item }">
                                        <v-icon left v-if="item.targetClientHasOne">mdi-card</v-icon>
                                        <v-icon left
                                                v-else-if="item.cardType && item.cardType.includes('QRCode')">mdi-qrcode</v-icon>
                                        <v-icon left v-else>mdi-card-off</v-icon>
                                        {{ item.label }}
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        <v-icon left v-if="item.targetClientHasOne">mdi-card</v-icon>
                                        <v-icon left
                                                v-else-if="item.cardType && item.cardType.includes('QRCode')">mdi-qrcode</v-icon>
                                        <v-icon left v-else>mdi-card-off</v-icon>
                                        {{ item.label }}
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>

                        <v-alert type="warning" v-if="hasQRCodeAssigned && !wvEnabled()">
                            Les QRCodes ne peuvent être imprimés que si vous êtes sur la
                            caisse
                        </v-alert>

                        <v-alert type="warning" v-if="hasProductAssignedToCardTypeWithoutCard && !convertToCredit">
                            Ce client ne possède pas certains supports ! Un badge est
                            obligatoire afin qu'il puisse utiliser ses produits.
                        </v-alert>
                    </div>

                    <div layout="row" layout-align="space-between center" class="mt-5">
                        <v-btn text @click="resetAssociation">
                            <v-icon left>mdi-arrow-left</v-icon>
                            Retour à l'e-billet
                        </v-btn>

                        <v-btn v-if="convertToCredit" text @click="convertEticketToCredit" color="success"
                               :disabled="!hasClient">
                            <v-icon left>mdi-check</v-icon>
                            Convertir le bon en cagnotte
                        </v-btn>
                        <v-btn v-else text @click="validConsumeEticket" color="success" :disabled="!hasClient && !allGoesOnQRCode"
                               :loading="validationLoading">
                            <v-icon left>mdi-check</v-icon>
                            Valider l'e-billet
                        </v-btn>
                    </div>
                </v-window-item>

                <v-window-item key="search">
                    <div class="text-h6 mb-3">
                        <v-btn icon @click="step = 0"><v-icon>mdi-arrow-left</v-icon></v-btn>
                        Liste des bons cadeaux en attente
                    </div>
                    <v-text-field outlined hide-details label="Rechercher..." v-model="search" />
                    <v-data-table :headers="searchHeaders" :items="giftVouchers" :server-items-length="totalGiftVouchers"
                                  :loading="giftVouchersLoading" loading-text="Chargement des bons cadeaux..." :search="search"
                                  @click:row="v => useSearchedVoucher(v)" :options.sync="pagination" :footer-props="{
                                      itemsPerPageOptions: [10, 25, 50, 100]
                                  }">
                        <template #[`item.BeginValidityDate`]="{ item }">{{
                            formatDateTimeHuman(item.BeginValidityDate)
                        }}</template>
                        <template #[`item.SheetLabel`]="{ item }">
                            <span style="display: inline-block; max-width: 150px;">{{
                                item.SheetLabel
                            }}</span>
                        </template>
                        <template #[`item.Firstname`]="{ item }">{{ item.Firstname }}
                            {{ item.Lastname }}</template>
                        <template #[`item.actions`]>
                            <v-btn icon color="primary">
                                <v-icon>mdi-arrow-right-circle-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-window-item>
            </v-window>
        </v-card-text>
    </v-card>
</template>

<script>
import _ from 'lodash'
import { Decimal } from 'decimal.js'
import C from 'neptune/consts'
import ApiVouchers from '~/api/vouchers'
import ApiProducts from '~/api/products'
import ApiClients from '~/api/clients'
import SpaApi from '~/api/spa'
import ClientForm from '~/components/clients/ClientForm'
import WebviewMixin from '~/mixins/webview'
import EventBus from '~/eventbus'
import utils from 'neptune/helpers/utils'
import AuthMixin from '@/mixins/auth.js'

export default {
    mixins: [AuthMixin, WebviewMixin],
    components: { ClientForm },
    props: {
        infoOnly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            C,
            step: 0,
            voucherID: '',
            validating: false,
            voucher: null,
            clientSearch: {
                loading: false,
                items: [],
                search: '',
                select: null
            },
            productCardAssignation: {},
            cardTypes: [],
            selectedProductID: null,
            failedToCheckVoucher: false,
            newClientMode: false,
            newClient: {},
            validationLoading: false,
            giftVouchers: [],
            giftVouchersLoading: false,
            search: '',
            searchHeaders: [
                { text: 'Date de début de validité', align: 'start', sortable: true, value: 'BeginValidityDate' },
                { text: 'Code', value: 'Code' },
                { text: 'Planche', value: 'SheetLabel' },
                { text: 'Nom', value: 'ClientSaleLastname' },
                { text: 'Prénom', value: 'ClientSaleFirstname' },
                { text: 'Pour', value: 'Firstname' },
                { text: 'Valeur', value: 'Value' },
                { text: '', value: 'actions', sortable: false }
            ],
            pagination: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['BeginValidityDate'],
                sortDesc: [false]
            },
            totalGiftVouchers: 0,
            associatedCards: [],
            spaProducts: [],
            focusInterval: null,
            convertToCredit: false,
            seller: ''
        }
    },

    watch: {
        'clientSearch.search' (val) {
            ((this.clientSearch.select &&
                val !== this.clientFullname(this.clientSearch.select)) ||
                !this.clientSearch.select) &&
                this.performClientSearch(val)
        },

        targetClient () {
            this.productCardAssignation = {}
        },

        search: _.debounce(function () {
            this.pagination.page = 1
            this.loadGiftVouchers()
        }, 300),

        pagination: {
            handler () {
                this.loadGiftVouchers()
            },
            deep: true
        }
    },

    created () {
        this.resetEticket()
    },

    mounted () {
        this.seller = this.loggedUser.FirstName
        this.focusInput()
        this.focusInterval = setInterval(() => {
            this.focusInput()
        }, 100)
        SpaApi.getSpaProducts({ State: true }).then(res => {
            this.spaProducts = res.data || []
        })
    },

    destroyed () {
        if (this.focusInterval) {
            clearInterval(this.focusInterval)
        }
    },

    computed: {
        voucherDeliveredProducts () {
            if (!this.deliveredProducts.length) {
                return
            }

            const items = this.deliveredProducts.map(i => {
                const product = this.mappedProducts[i.ProductID]
                return Object.assign(i, {
                    product,
                    action: this.getProductAction(product)
                })
            })
            items.sort((p1) => p1.action.key === 'nothing' ? -1 : 1)

            return items
        },

        mappedProducts () {
            const mapped = {}
            for (const product of this.voucher.Products) {
                mapped[product.ID] = product
            }

            return mapped
        },

        mappedCardTypes () {
            const mapped = {}
            for (const cardType of this.cardTypes) {
                mapped[cardType.CardType] = cardType
            }

            return mapped
        },

        selectedProduct () {
            return this.mappedProducts[this.selectedProductID]
        },

        hasClient () {
            return (this.voucher && this.voucher.Voucher && this.voucher.Voucher.ClientGiftID) ||
                this.newClientMode || (this.clientSearch && this.clientSearch.select && this.clientSearch.select.ID)
        },

        allCanGoOnQRCode () {
            return this.voucher && this.voucher.Voucher && this.voucher.Voucher.DeliveredItems &&
                this.voucher.Voucher.DeliveredItems.every(i => {
                    const cardtypes = this.productCompatibleCardTypes(i.ProductID)
                    return cardtypes && cardtypes.some(p => p.cardType.includes('QRCode'))
                })
        },

        allGoesOnQRCode () {
            if (!this.allCanGoOnQRCode) {
                return false // Means some of the delivered products aren't subscriptions, so they aren't going on QRCodes
            }
            for (const cardtype of Object.values(this.productCardAssignation)) {
                if (!cardtype.includes('QRCode')) {
                    return false
                }
            }
            return true
        },

        targetClient () {
            if (this.voucher && this.voucher.Voucher && this.voucher.Voucher.ClientGiftID) {
                return this.voucher.ClientGift
            } else if (this.newClientMode) {
                return this.newClient
            } else if (this.clientSearch && this.clientSearch.select && this.clientSearch.select.ID) {
                return this.clientSearch.select
            } else {
                return null
            }
        },

        // combine voucher delivered items & selected product in array of product's ids
        deliveredProducts () {
            if (!this.voucher || !this.voucher.Voucher) {
                return []
            }

            const items = this.voucher.Voucher.DeliveredItems.filter(i => i.UsedAmount < i.Amount).map(di => Object.assign(di, { Amount: parseInt(di.Amount) })) || []
            if (this.selectedProductID) {
                items.push({ ProductID: this.selectedProductID, Amount: 1, UsedAmount: 0 })
            }

            // manage packed products
            const packedProducts = items.map(i => this.voucher.Products.find(p => p.ID === i.ProductID)).map(p => this.recurseGetPackedProducts(p)).flat()
            for (const packedProduct of packedProducts) {
                const existingIndex = items.findIndex(i => i.ProductID === packedProduct.product.ID)
                if (existingIndex !== -1) {
                    items[existingIndex].Amount += packedProduct.amount
                } else {
                    items.push({ ProductID: packedProduct.product.ID, Amount: packedProduct.amount, UsedAmount: 0 })
                }
            }

            return items
        },

        deliveredSubscriptions () {
            return this.deliveredProducts.filter(i => this.mappedProducts[i.ProductID] && this.mappedProducts[i.ProductID].Subscription)
        },

        deliveredCards () {
            const out = []
            const cards = this.deliveredProducts.filter(i => this.mappedProducts[i.ProductID] && this.mappedProducts[i.ProductID].CardType)
            for (const card of cards) {
                for (let i = 0; i < parseInt(card.Amount); i++) {
                    out.push(card)
                }
            }

            return out
        },

        hasQRCodeAssigned () {
            return Object.values(this.productCardAssignation).includes('QRCode')
        },

        hasProductAssignedToCardTypeWithoutCard () {
            let missingCardtype = false
            for (const productID in this.productCardAssignation) {
                for (const cardSlot of this.productCompatibleCardTypes(productID)) {
                    if (cardSlot.cardType.includes('QRCode')) {
                        missingCardtype = false
                        break
                    }
                    if (cardSlot.cardType === this.productCardAssignation[productID] && !cardSlot.targetClientHasOne) {
                        missingCardtype = true
                    }
                }
            }

            return missingCardtype
        }
    },

    methods: {
        formatMoney (amount) {
            return new Decimal(amount).toFixed(2)
        },

        setCode (code) {
            this.$nextTick(() => {
                this.voucherID = code
                this.validateEticket()
            })
        },

        setSeller (name) {
            this.seller = name
        },

        recurseGetPackedProducts (product) {
            if (!product.PackedProducts || !product.PackedProducts.length) {
                return []
            }

            const packedProducts = {}

            for (const packedProduct of product.PackedProducts) {
                const p = this.voucher.Products.find(p => p.ID === packedProduct.ProductID)
                if (!packedProducts[p.ID]) {
                    packedProducts[p.ID] = { product: p, amount: 0 }
                }

                packedProducts[p.ID].amount += parseInt(packedProduct.Amount)

                const subPackedProducts = this.recurseGetPackedProducts(p)
                for (const spp of subPackedProducts) {
                    if (!packedProducts[spp.product.ID]) {
                        packedProducts[spp.product.ID] = { product: spp.product, amount: 0 }
                    }

                    packedProducts[spp.product.ID].amount += parseInt(spp.amount)
                }
            }

            return Object.values(packedProducts)
        },

        searchEticket () {
            this.search = this.voucherID
            this.loadGiftVouchers()
        },

        loadGiftVouchers () {
            this.giftVouchersLoading = true

            const filters = {
                search: this.search,
                states: [2]
            }

            ApiVouchers.getGiftVouchers(Object.assign(utils.getPaginationFromDataTableOptions(this.pagination), filters)).then(res => {
                this.totalGiftVouchers = res.data.Total
                this.giftVouchers = res.data.Vouchers || []
                this.step = 2
            }).catch(err => {
                console.error(err)
                this.$snotify.error('Impossible de récupérer les bons en attente')
            }).finally(() => {
                this.giftVouchersLoading = false
            })
        },

        focusInput () {
            this.$nextTick(() => {
                if (this.$refs.voucherField) {
                    this.$refs.voucherField.focus()
                }
            })
        },

        associateCard (cardType, deliveredCardIndex) {
            this.wvAssociateCard(cardType, this.clientSearch.select.ID).then((cardNumber) => {
                const existingIndex = this.associatedCards.findIndex(c => c.deliveredCardIndex === deliveredCardIndex)
                if (existingIndex !== -1) {
                    this.associatedCards[existingIndex].CardNumber = cardNumber
                } else {
                    this.associatedCards.push({ deliveredCardIndex, CardNumber: cardNumber, CardType: this.mappedProducts[this.deliveredCards[deliveredCardIndex].ProductID].CardType })
                }
            })
        },

        getProductAction (product) {
            const associatedSpaProduct = this.spaProducts.find(p => p.Product.ID === product.ID)
            if (product.Subscription && product.Subscription.Type !== 7 && product.Subscription.Type !== 0) {
                return { text: 'Ajoute ou recharge un abonnement sur un support', icon: 'mdi-credit-card-plus' }
            } else if (associatedSpaProduct) {
                return { text: 'Valide ce produit SPA pour ce client', icon: 'mdi-spa' }
            } else if (product.VoucherCreditValue || product.Voucher?.Type === C.VOUCHER_TYPES.CREDIT) {
                return { text: 'Crédite la cagnotte du client', icon: 'mdi-account-cash' }
            } else if (product.VoucherCreditUnitValue || product.Voucher?.Type === C.VOUCHER_TYPES.CREDIT_UNIT) {
                return { text: 'Crédite les unités du client', icon: 'mdi-account-arrow-left' }
            } else if (product.CardType) {
                return { text: "Permet l'association d'une carte/badge au client", icon: 'mdi-credit-card-scan' }
            } else {
                return { text: "Aucune action n'est engendrée par ce produit", icon: 'mdi-close-box', color: 'warning', key: 'nothing' }
            }
        },

        productCompatibleCardTypes (productID) {
            const product = this.mappedProducts[productID]
            if (!product || !product.Subscription || !product.Subscription.CardType || product.Subscription.CardType.length === 0) {
                return []
            }

            const out = product.Subscription.CardType.map(c => ({ cardType: c, label: this.mappedCardTypes[c] ? this.mappedCardTypes[c].Label.FR : (c === 'wristband-QRCode' ? 'Bracelet QRCode' : c) }))

            let clientCardTypes = (this.targetClient && this.targetClient.Cards) ? this.targetClient.Cards.filter(c => c.CardNumber && c.CardNumber.length > 0).map(c => c.CardType) : []
            clientCardTypes = clientCardTypes.concat(this.associatedCards.map(c => c.CardType))
            for (const cardTypeIdx in out) {
                if (clientCardTypes.includes(out[cardTypeIdx].cardType)) {
                    out[cardTypeIdx].targetClientHasOne = true
                }
            }

            if (!this.productCardAssignation[productID]) {
                const compatibleCardTypes = out.filter(c => c.targetClientHasOne)
                this.setProductCardtype(productID, compatibleCardTypes.length > 0 ? compatibleCardTypes[0].cardType : out[0].cardType)
            }

            return out
        },

        useSearchedVoucher (voucher) {
            if (voucher.Code) {
                this.voucherID = voucher.Code
            } else {
                this.voucherID = voucher.ID
            }
            this.validateEticket().then(() => {
                this.step = 0
            })
        },

        resetEticket () {
            this.voucher = null
            this.voucherID = ''
            this.convertToCredit = false
            this.resetAssociation()
        },

        resetAssociation () {
            this.validationLoading = false
            this.clientSearch.select = null
            this.selectedProductID = null
            this.newClientMode = false
            this.newClient = {
                ClientType: 1,
                OrgInfo: {},
                Address: {},
                Contact: {},
                BirthDate: ''
            }
            this.step = 0
        },

        validateEticket () {
            this.failedToCheckVoucher = false
            this.voucher = null
            return new Promise((resolve, reject) => {
                ApiVouchers.scanVoucher(this.voucherID).then(
                    res => {
                        this.voucher = res.data
                        resolve()
                    }).catch(
                    (err) => {
                        this.validating = false
                        this.failedToCheckVoucher = true
                        reject(err)
                    }
                )
            })
        },

        onProductSelected () {
            this.step = 1
            ApiProducts.getCardTypes().then(res => {
                this.cardTypes = res.data ?? []
            }).catch(() => {
                this.$snotify.error('Impossible de récupérer les types de badge')
            })

            setTimeout(() => {
                this.$refs.clientSearchField.focus()
            }, 100)
        },

        validConsumeEticket () {
            if (
                (this.voucher.Voucher && this.voucher.Voucher.ClientGiftID) ||
                (this.clientSearch && this.clientSearch.select && this.clientSearch.select.ID) ||
                (this.newClientMode && this.$refs.clientForm.isValid()) ||
                this.allGoesOnQRCode
            ) {
                this.validationLoading = true
                if (this.newClient.BirthDate === '') {
                    this.newClient.BirthDate = '0001-01-01T00:00:00Z'
                }

                ApiVouchers.validateVoucher({
                    VoucherID: this.voucher.Voucher.ID,
                    SelectedProductID: this.selectedProductID,
                    IsNewClient: this.newClientMode,
                    NewClient: this.newClientMode ? this.newClient : undefined,
                    ProductCardAssignation: this.productCardAssignation,
                    AssociatedCards: this.associatedCards,
                    ClientID:
                        this.clientSearch && this.clientSearch.select && this.clientSearch.select.ID
                            ? this.clientSearch.select.ID
                            : undefined,
                    SellerName: this.seller
                }).then(
                    (res) => {
                        const successMessage = "L'e-billet a été consommé."

                        EventBus.$emit('voucher-consumed')

                        if (!this.wvEnabled()) {
                            this.$snotify.success(successMessage)
                        }
                        // emit event for cashregister to print voucher
                        if (this.wvEnabled()) {
                            this.wvVoucherValidated(res.data)
                            this.wvToast('success', successMessage)
                        } else if (res.data && res.data.AccessControlVouchersGenerationNeeded) {
                            this.$snotify.error(
                                "Il faut être sur la caisse pour pouvoir imprimer les bons d'accès générés par un bon cadeau."
                            )
                        }
                        this.$emit('validated')
                        this.$nextTick(() => {
                            this.resetEticket()
                        })
                    }).catch(
                    () => {
                        this.$snotify.error(
                            'Une erreur est survenue, veuillez réessayer'
                        )
                    }
                ).finally(() => {
                    this.validationLoading = false
                })
            } else {
                this.$snotify.error('Veuillez contrôler les valeurs saisies')
            }
        },

        convertEticketToCredit () {
            if (
                (this.voucher.Voucher && this.voucher.Voucher.ClientGiftID) ||
                (this.clientSearch && this.clientSearch.select && this.clientSearch.select.ID) ||
                (this.newClientMode && this.$refs.clientForm.isValid())
            ) {
                if (this.newClient.BirthDate === '') {
                    this.newClient.BirthDate = '0001-01-01T00:00:00Z'
                }

                ApiVouchers.convertVoucherGiftToCredit({
                    VoucherID: this.voucher.Voucher.ID,
                    IsNewClient: this.newClientMode,
                    NewClient: this.newClientMode ? this.newClient : undefined,
                    ClientID:
                        this.clientSearch && this.clientSearch.select && this.clientSearch.select.ID
                            ? this.clientSearch.select.ID
                            : undefined,
                    SellerName: this.seller
                }).then(
                    () => {
                        EventBus.$emit('voucher-consumed')

                        const successMessage = "La valeur de l'e-billet a été converti en cagnotte."
                        if (!this.wvEnabled()) {
                            this.$snotify.success(successMessage)
                        } else {
                            this.wvToast('success', successMessage)
                        }

                        this.$emit('validated')
                        this.$nextTick(() => {
                            this.resetEticket()
                        })
                    }).catch(
                    () => {
                        this.$snotify.error(
                            'Une erreur est survenue, veuillez réessayer'
                        )
                    }
                )
            } else {
                this.$snotify.error('Veuillez contrôler les valeurs saisies')
            }
        },

        close () {
            this.$emit('validated')
        },

        isPartiallyUsed (voucher) {
            return voucher.DeliveredItems && voucher.DeliveredItems.some(i => i.UsedAmount > 0)
        },

        productsAlreadyCreditCagnotte (voucher) {
            return voucher.DeliveredItems && voucher.DeliveredItems.every(i => i.product.VoucherCreditValue)
        },

        performClientSearch: _.debounce(function (search) {
            this.clientSearch.loading = true
            ApiClients.getClients({ Search: search }).then(res => {
                if (search === this.clientSearch.search) {
                    this.clientSearch.items = res.data.Items || []
                }
                if (this.voucher && this.voucher.ClientGift && this.voucher.ClientGift.ID) {
                    this.clientSearch.items.push(this.voucher.ClientGift)
                    this.clientSearch.select = this.voucher.ClientGift
                }
                this.clientSearch.loading = false
            })
        }, 100),

        setProductCardtype (productID, cardtype) {
            this.$set(this.productCardAssignation, productID, cardtype)
        }
    }
}
</script>
